import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useUpdate, Title, useNotify } from "react-admin";
import { Paper, TextField, Button, Stack, Snackbar } from "@mui/material";
import { CALL, METHOD } from "../../utils/api";

import '../../scss/mint.scss';

const Mint = () => {
    const defaultValues = {
        address: "",
        amount: 0
    };
    const { handleSubmit, control } = useForm({ defaultValues });
    const [update, { isLoading: isSubmitting }] = useUpdate();
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();
    const notify = useNotify();

    const onSubmit = (data) => {
        update(
            "token/mint",
            { data },
            {
                onSuccess: () => { navigate('/wallets'); },
                onError: (err) => {
                    setErrorMessage(err.message);
                    setShowError(true);
                }
            }
        );
    };

    const closeErrorBox = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowError(false);
    };

    const getAddressInfo = (event) => {
        CALL(METHOD.GET, 'wallets/data/' + event.target.value, {}).catch((err) => {
            if (err && err.code === 400)
                notify(`The address does not exist!`, { type: 'warning' })
        });
    }        

    return (
        <Paper className="container">
            <Title title="Mint Token" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <Controller
                        name="address"
                        render={({ field }) => <TextField label="Wallet Address" {...field} onBlur={getAddressInfo} />}
                        control={control}
                    />
                    <Controller
                        name="amount"
                        render={({ field }) => <TextField type="number" label="Mint Amount" {...field} />}
                        control={control}
                    />
                    <Button type="submit" disabled={isSubmitting}>
                        Mint
                    </Button>
                </Stack>
            </form>
            <Snackbar
                open={showError}
                autoHideDuration={6000}
                message={errorMessage}
                onClose={closeErrorBox}
                severity="error"
            />
        </Paper>
    );
};

export default Mint;