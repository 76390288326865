import ChatBubbleIcon from '@mui/icons-material/AccountBalanceWallet';
import WalletsList from './walletList';
import WalletCreate from './walletCreate';

const modules = {
    list: WalletsList,
    create: WalletCreate,
    icon: ChatBubbleIcon,
};

export default modules;