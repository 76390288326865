import AccountBalanceWallet from '@mui/icons-material/AccountBalanceWallet';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

import {
    ListBase,
    Title,
    TopToolbar,
    CreateButton,
    useNotify,
} from 'react-admin';

import { Paper, Button } from "@mui/material";
import { CALL, METHOD } from "../../utils/api";

import '../../scss/wallets.scss';
import { SimpleList } from '../../components/DataList.component';
import { copyToClipboard } from '../../utils/utils';


const WalletsList = () => {

    const notify = useNotify();

    const getAddress = (data) => {
        return <span>{data.address}</span>
    }

    const onSelectAddress = (data) => {
        copyToClipboard(data.address)
            .then(() => notify(`Address copied!`, { type: 'info' }));
    }

    const getLastAction = (data) => {
        if (data._mint) {
            return `Mint ${data._mint} CLNX`;
        } else if (data._transfer) {
            if (data._to) {
                return `Transfer ${data._transfer} CLNX to Wallet ${data._to} (Fee ${data._fee})`;
            } else if (data._from) {
                return `Recived ${data._transfer} CLNX from Wallet ${data._from}`;
            }
            return `Transfer ${data._transfer} CLNX`;
        } else if (data._burn) {
            return `Burn ${data._burn} CLNX`;
        } else if (data._freez) {
            return `Freez ${data._freez} CLNX`;
        } else if (data._unfreez) {
            return `Unfreez ${data._unfreez} CLNX`;
        } else if (data._stack) {
            return `Stack ${data._stack} CLNX`;
        } else if (data._unstack) {
            return `Unstack ${data._unstack} CLNX`;
        } else if (data._blacklist != null) {
            if (data._blacklist) {
                return `Added to Blacklist`;
            }
            return `Removed from Blacklist`;
        }
    }

    const getValue = (data) => {
        const availableBalance = data.value - (data.frozenValue || 0) - (data.stackedValue || 0);
        let values = [<span key="value">{data.value + ' CLNX'}</span>];
        if (availableBalance !== data.value)
            values.push(<span key="available" className="avail-balance">Available {availableBalance + ' CLNX'}</span>);
        if (data.frozenValue) {
            values.push(<br key="frozen_br" />);
            values.push(<span key="frozen">{data.frozenValue + ' CLNX'} Frozen</span>);
        }
        if (data.stackedValue) {
            values.push(<br key="stacked_br" />);
            values.push(<span key="stacked">{data.stackedValue + ' CLNX'} Stacked</span>);
        }
        return <div className="text-right value-box">{values}</div>;
    }

    const getWalletAvatar = (data) => {
        if (data.blacklisted)
            return <NotInterestedIcon />;
        return <AccountBalanceWallet />;
    }


    const registerAdmin = () => {
        CALL(METHOD.POST, 'wallets/admin', {}).then((result) => {
            console.log(result)
        }).catch((err) => {
            console.log(err)
        });
    }


    return (
        <Paper className="container wallets">
            <TopToolbar>
                <CreateButton
                    variant="contained"
                    label="New Wallet"
                    sx={{ marginLeft: 2 }}
                />
                <Button
                    variant="contained"
                    onClick={registerAdmin}
                >
                    Register Admin
                </Button>
            </TopToolbar>
            <ListBase >
                <Title defaultTitle={"Wallets"} />
                <SimpleList
                    primaryText={record => getAddress(record)}
                    secondaryText={record => getLastAction(record.value)}
                    tertiaryText={record => getValue(record.value)}
                    leftAvatar={record => getWalletAvatar(record.value)}
                    onSelect={(record) => onSelectAddress(record)}
                />
            </ListBase>
        </Paper>
    );
};

export default WalletsList;