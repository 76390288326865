import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useUpdate, Title, useNotify } from "react-admin";
import { Paper, Card, TextField, Button, Stack, Snackbar, CardHeader } from "@mui/material";
import ArticleIcon from '@mui/icons-material/Article';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import { CALL, METHOD } from "../../utils/api";

import '../../scss/stack.scss';

const TokenStack = () => {
    const defaultValues = {
        address: "",
        amount: 0
    };
    const revertDefaultValues = {
        address: "",
        amount: 0
    };
    const { handleSubmit, control } = useForm({ defaultValues });
    const { handleSubmit: handleSubmitRevert, control: revertControl } = useForm({ defaultValues: revertDefaultValues });

    const [update, { isLoading: isSubmitting }] = useUpdate();

    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [stackWalletValue, setStackWalletValue] = useState({});
    const [unStackingWalletValue, setUnStackingWalletValue] = useState({});


    const navigate = useNavigate();
    const notify = useNotify();

    const onSubmit = (data) => {
        update(
            "token/stack",
            { data },
            {
                onSuccess: () => { navigate('/wallets'); },
                onError: (err) => {
                    setErrorMessage(err.message);
                    setShowError(true);
                }
            }
        );
    };

    const onSubmitRevert = (data) => {
        update(
            "token/unstack",
            { data },
            {
                onSuccess: () => { navigate('/wallets'); },
                onError: (err) => {
                    setErrorMessage(err.message);
                    setShowError(true);
                }
            }
        );
    };

    const closeErrorBox = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowError(false);
    };

    const getAddressInfo = (event) => {
        CALL(METHOD.GET, 'wallets/data/' + event.target.value, {}).then((result) => {
            if (!result || !result.data) return;
            setStackWalletValue({ min: 0, max: result.data.value });
        }).catch((err) => {
            setStackWalletValue({});
            if (err && err.code === 400)
                notify(`The address does not exist!`, { type: 'warning' })
        });
    }

    const getUnstackingAddressInfo = (event) => {
        CALL(METHOD.GET, 'wallets/data/' + event.target.value, {}).then((result) => {
            if (!result || !result.data) return;
            setUnStackingWalletValue({ min: 0, max: result.data.stackedValue });
        }).catch((err) => {
            setUnStackingWalletValue({});
            if (err && err.code === 400)
                notify(`The address does not exist!`, { type: 'warning' })
        });
    }

    return (
        <Paper className="container">
            <Title title="Stack Token" />
            <Stack spacing={2}>
                <Card variant="outlined">
                    <CardHeader
                        avatar={
                            <ArticleIcon />
                        }
                        title="Stack Token"
                    />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={2}>
                            <Controller
                                name="address"
                                render={({ field }) => <TextField label="Wallet Address" {...field} onBlur={getAddressInfo} />}
                                control={control}
                            />
                            <Controller
                                name="amount"
                                render={({ field }) => <TextField type="number" inputProps={stackWalletValue} label={"Stacking Amount" + (stackWalletValue.max != null ? ` (${stackWalletValue.max} CLNX)` : '')} {...field} />}
                                control={control}
                            />
                            <Button type="submit" disabled={isSubmitting}>
                                Stack
                            </Button>
                        </Stack>
                    </form>
                </Card>
                <Card variant="outlined">
                    <CardHeader
                        avatar={
                            <RestorePageIcon />
                        }
                        title="Revert Stacked Token"
                    />
                    <form onSubmit={handleSubmitRevert(onSubmitRevert)}>
                        <Stack spacing={2}>
                            <Controller
                                name="address"
                                render={({ field }) => <TextField label="Wallet Address" {...field} onBlur={getUnstackingAddressInfo} />}
                                control={revertControl}
                            />
                            <Controller
                                name="amount"
                                render={({ field }) => <TextField type="number" inputProps={unStackingWalletValue} label={"Un Stacking Amount" + (unStackingWalletValue.max != null ? ` (${unStackingWalletValue.max} CLNX)` : '')} {...field} />}
                                control={revertControl}
                            />
                            <Button type="submit" disabled={isSubmitting}>
                                Revert
                            </Button>
                        </Stack>
                    </form>
                </Card>
            </Stack>
            <Snackbar
                open={showError}
                autoHideDuration={6000}
                message={errorMessage}
                onClose={closeErrorBox}
                severity="error"
            />
        </Paper>
    );
};

export default TokenStack;