import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useUpdate, Title, useNotify } from "react-admin";
import { Paper, Card, TextField, Button, Stack, Snackbar, CardHeader } from "@mui/material";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { CALL, METHOD } from "../../utils/api";

import '../../scss/blacklist.scss';

const Blacklist = () => {
    const defaultValues = {
        address: ""
    };
    const defaultUnBlacklistValues = {
        address: ""
    };    
    const { handleSubmit, control } = useForm({ defaultValues });
    const { handleSubmit: handleSubmitUnBlacklist, control: unblacklistControl } = useForm({ defaultValues: defaultUnBlacklistValues });
    const [update, { isLoading: isSubmitting }] = useUpdate();
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();
    const notify = useNotify();

    const onSubmit = (data) => {
        update(
            "wallets/blacklist",
            { data },
            {
                onSuccess: () => { navigate('/wallets'); },
                onError: (err) => {
                    setErrorMessage(err.message);
                    setShowError(true);
                }
            }
        );
    };

    const onSubmitUnBlacklist = (data) => {
        update(
            "wallets/unblacklist",
            { data },
            {
                onSuccess: () => { navigate('/wallets'); },
                onError: (err) => {
                    setErrorMessage(err.message);
                    setShowError(true);
                }
            }
        );
    };

    const closeErrorBox = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowError(false);
    };

    const getAddressInfo = (event) => {
        CALL(METHOD.GET, 'wallets/data/' + event.target.value, {}).catch((err) => {
            if (err && err.code === 400)
                notify(`The address does not exist!`, { type: 'warning' })
        });
    }    

    return (
        <Paper className="container blacklist-tkn">
            <Title title="Blacklist Wallet" />
            <Stack spacing={2}>
                <Card variant="outlined">
                    <CardHeader
                        avatar={
                            <DoDisturbIcon />
                        }
                        title="Blacklist Wallet"
                    />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={2}>
                            <Controller
                                name="address"
                                render={({ field }) => <TextField label="Wallet Address" {...field} onBlur={getAddressInfo} />}
                                control={control}
                            />
                            <Button type="submit" disabled={isSubmitting}>
                                Blacklist
                            </Button>
                        </Stack>
                    </form>
                </Card>
                <Card variant="outlined">
                    <CardHeader
                        avatar={
                            <RestartAltIcon />
                        }
                        title="Revert Blacklisted Wallet"
                    />
                    <form onSubmit={handleSubmitUnBlacklist(onSubmitUnBlacklist)}>
                        <Stack spacing={2}>
                            <Controller
                                name="address"
                                render={({ field }) => <TextField label="Wallet Address" {...field} onBlur={getAddressInfo} />}
                                control={unblacklistControl}
                            />
                            <Button type="submit" disabled={isSubmitting}>
                                Remove from Blacklist
                            </Button>
                        </Stack>
                    </form>
                </Card>
            </Stack>
            <Snackbar
                open={showError}
                autoHideDuration={6000}
                message={errorMessage}
                onClose={closeErrorBox}
                severity="error"
            />
        </Paper>
    );
};

export default Blacklist;