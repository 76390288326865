import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useUpdate, Title, useNotify } from "react-admin";
import { Paper, Card, TextField, Button, Stack, Snackbar, CardHeader } from "@mui/material";
import AcUnitIcon from '@mui/icons-material/AcUnit';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import { CALL, METHOD } from "../../utils/api";

import '../../scss/freez.scss';

const Freez = () => {
    const defaultValues = {
        address: "",
        amount: 0
    };
    const defaultUnfreezValues = {
        address: "",
        amount: 0
    };
    const { handleSubmit, control } = useForm({ defaultValues });
    const { handleSubmit: handleSubmitUnfreez, control: unfreezControl } = useForm({ defaultValues: defaultUnfreezValues });
    const [update, { isLoading: isSubmitting }] = useUpdate();
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [freezWalletValue, setFreezWalletValue] = useState({});
    const [unFreezingWalletValue, setUnFreezingWalletValue] = useState({});

    const navigate = useNavigate();
    const notify = useNotify();

    const onSubmit = (data) => {
        update(
            "token/freez",
            { data },
            {
                onSuccess: () => { navigate('/wallets'); },
                onError: (err) => {
                    setErrorMessage(err.message);
                    setShowError(true);
                }
            }
        );
    };

    const onSubmitUnFreez = (data) => {
        update(
            "token/unfreez",
            { data },
            {
                onSuccess: () => { navigate('/wallets'); },
                onError: (err) => {
                    setErrorMessage(err.message);
                    setShowError(true);
                }
            }
        );
    };

    const closeErrorBox = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowError(false);
    };

    const getAddressInfo = (event) => {
        CALL(METHOD.GET, 'wallets/data/' + event.target.value, {}).then((result) => {
            if (!result || !result.data) return;
            setFreezWalletValue({ min: 0, max: result.data.value });
        }).catch((err) => {
            setFreezWalletValue({});
            if (err && err.code === 400)
                notify(`The address does not exist!`, { type: 'warning' })
        });
    }

    const getUnFreezingAddressInfo = (event) => {
        CALL(METHOD.GET, 'wallets/data/' + event.target.value, {}).then((result) => {
            if (!result || !result.data) return;
            setUnFreezingWalletValue({ min: 0, max: result.data.frozenValue });
        }).catch((err) => {
            setUnFreezingWalletValue({});
            if (err && err.code === 400)
                notify(`The address does not exist!`, { type: 'warning' })
        });
    }    

    return (
        <Paper className="container">
            <Title title="Freez Token" />
            <Stack spacing={2}>
                <Card variant="outlined">
                    <CardHeader
                        avatar={
                            <AcUnitIcon />
                        }
                        title="Freez Token"
                    />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={2}>
                            <Controller
                                name="address"
                                render={({ field }) => <TextField label="Wallet Address" {...field} onBlur={getAddressInfo}  />}
                                control={control}
                            />
                            <Controller
                                name="amount"
                                render={({ field }) => <TextField type="number" inputProps={freezWalletValue} label={"Freezing Amount" + (freezWalletValue.max != null ? ` (${freezWalletValue.max} CLNX)` : '')} {...field} />}
                                control={control}
                            />
                            <Button type="submit" disabled={isSubmitting}>
                                Freez
                            </Button>
                        </Stack>
                    </form>
                </Card>
                <Card variant="outlined">
                    <CardHeader
                        avatar={
                            <AutoModeIcon />
                        }
                        title="Revert Frozen Token"
                    />
                    <form onSubmit={handleSubmitUnfreez(onSubmitUnFreez)}>
                        <Stack spacing={2}>
                            <Controller
                                name="address"
                                render={({ field }) => <TextField label="Wallet Address" {...field} onBlur={getUnFreezingAddressInfo}  />}
                                control={unfreezControl}
                            />
                            <Controller
                                name="amount"
                                render={({ field }) => <TextField type="number" inputProps={unFreezingWalletValue} label={"Un Freezing Amount" + (unFreezingWalletValue.max != null ? ` (${unFreezingWalletValue.max} CLNX)` : '')} {...field} />}
                                control={unfreezControl}
                            />
                            <Button type="submit" disabled={isSubmitting}>
                                Revert
                            </Button>
                        </Stack>
                    </form>
                </Card>                
            </Stack>
            <Snackbar
                open={showError}
                autoHideDuration={6000}
                message={errorMessage}
                onClose={closeErrorBox}
                severity="error"
            />
        </Paper>
    );
};

export default Freez;