import * as React from "react";
import { Admin, Resource } from 'react-admin';
import authProvider from './utils/authProvider';
import { dataProvider } from "./utils/dataProvider";
import Wallets from "./pages/wallets";
import Freez from "./pages/freez";
import Mint from "./pages/mint";
import Stack from "./pages/stack";
import Blacklist from "./pages/blacklist";
import TransferToken from "./pages/transfer";

const App = () => (
  <Admin dataProvider={dataProvider} authProvider={authProvider} >
    <Resource name="wallets" options={{ label: 'Wallets' }} {...Wallets} />
    <Resource name="mint" options={{ label: 'Mint' }} {...Mint} />
    <Resource name="transfer" options={{ label: 'Transfer' }} {...TransferToken} />    
    <Resource name="stack" options={{ label: 'Stack' }} {...Stack} />
    <Resource name="freez" options={{ label: 'Freez'}} {...Freez} />
    <Resource name="blacklist" options={{ label: 'Blacklist' }} {...Blacklist} />
  </Admin >
);

export default App;