import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useUpdate, Title, useNotify } from "react-admin";
import { Paper, TextField, Button, Stack, Snackbar } from "@mui/material";

import '../../scss/transfertoken.scss';
import { CALL, METHOD } from "../../utils/api";

const TransferToken = () => {
    const defaultValues = {
        fromAddress: "",
        toAddress: "",
        amount: 0
    };
    const { handleSubmit, control } = useForm({ defaultValues });
    const [update, { isLoading: isSubmitting }] = useUpdate();
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [walletValue, setWalletValue] = useState({});

    const navigate = useNavigate();
    const notify = useNotify();

    const onSubmit = (data) => {
        update(
            "token/transfer",
            { data },
            {
                onSuccess: () => { navigate('/wallets'); },
                onError: (err) => {
                    setErrorMessage(err.message);
                    setShowError(true);
                }
            }
        );
    };

    const closeErrorBox = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowError(false);
    };

    const getAddressInfo = (event) => {
        const address = event.target.value;
        CALL(METHOD.GET, 'wallets/data/' + address, { notify }).then((result) => {
            if (!result || !result.data) return;
            setWalletValue({ min: 0, max: result.data.value });
        }).catch((err) => {
            setWalletValue({});
            if (err && err.code === 400)
                notify(`The address ${address} does not exist!`, { type: 'warning' })
        });
    }

    const getDestAddressInfo = (event) => {
        const address = event.target.value;
        CALL(METHOD.GET, 'wallets/data/' + address, { notify }).catch((err) => {
            if (err && err.code === 400)
                notify(`The address ${address} does not exist!`, { type: 'warning' })
        });
    }

    return (
        <Paper className="container transfer-tkn">
            <Title title="Transfer Token" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <Controller
                        name="fromAddress"
                        render={({ field }) => <TextField label="From Wallet Address" {...field} onBlur={getAddressInfo} />}
                        control={control}
                    />
                    <Controller
                        name="toAddress"
                        render={({ field }) => <TextField inputProps={walletValue} label="To Wallet Address" {...field} onBlur={getDestAddressInfo} />}
                        control={control}
                    />
                    <Controller
                        name="amount"
                        render={({ field }) => <TextField type="number" label={"Transfer Amount" + (walletValue.max != null ? ` (${walletValue.max} CLNX)` : '')} {...field} />}
                        control={control}
                    />
                    <Button type="submit" disabled={isSubmitting}>
                        Transfer
                    </Button>
                </Stack>
            </form>
            <Snackbar
                open={showError}
                autoHideDuration={6000}
                message={errorMessage}
                onClose={closeErrorBox}
                severity="error"
            />
        </Paper>
    );
};

export default TransferToken;