import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Button, Snackbar, Stack, TextareaAutosize, Skeleton } from "@mui/material";

import '../../scss/walletcreate.scss';
import { CALL, METHOD } from "../../utils/api";

const WalletCreate = () => {


    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(true);
    const [walletInfo, setWalletInfo] = useState({ address: "", secrets: { certificate: "", privateKey: "" } });

    const navigate = useNavigate();


    useEffect(() => {
        const timer = setTimeout(() => {
            setIsSubmitting(true);
            CALL(METHOD.POST, 'wallets', {}).then((result) => {
                if (!result || !result.data || !result.data.address || !result.data.secrets || !result.data.secrets.certificate || !result.data.secrets.privateKey)
                    throw new Error('Failed to create wallet!');
                setWalletInfo(result.data);
            }).catch((err) => {
                setErrorMessage(err && err.message ? err.message : err);
            }).finally(() => {
                setIsSubmitting(false);
            });
        }, 0);

        return () => {
            clearTimeout(timer);
        }
    }, []);


    const closeErrorBox = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowError(false);
    };

    if (isSubmitting) return (
        <Paper className="container wallet-crt">
            <Stack spacing={2}>
                <Skeleton variant="rectangular" width='100%' height={30} animation="wave" />
                <Skeleton variant="rectangular" width='100%' height={118} animation="wave" />
                <Skeleton variant="rectangular" width='100%' height={118} animation="wave" />
                <Button onClick={() => navigate('/wallets')}>
                    Close
                </Button>
            </Stack>

            <Snackbar
                open={showError}
                autoHideDuration={6000}
                message={errorMessage}
                onClose={closeErrorBox}
                severity="error"
            />
        </Paper>
    );

    return (
        <Paper className="container wallet-crt">
            <Stack spacing={2}>
                <div>
                    <strong>Wallet Address:</strong> {walletInfo.address}
                </div>
                <div>
                    <strong>Public Key</strong>
                    <br/>
                    <TextareaAutosize
                        className="width-100"
                        value={walletInfo.secrets.certificate}
                        readOnly={true}
                    />
                </div>
                <div>
                    <strong>Private Key</strong>
                    <br />
                    <TextareaAutosize
                        className="width-100"
                        value={walletInfo.secrets.privateKey}
                        readOnly={true}
                    />
                </div>

                <Button onClick={() => navigate('/wallets')}>
                    Close
                </Button>
            </Stack>

            <Snackbar
                open={showError}
                autoHideDuration={6000}
                message={errorMessage}
                onClose={closeErrorBox}
                severity="error"
            />
        </Paper>
    );
};

export default WalletCreate;