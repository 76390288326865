import authProvider from './authProvider';
import config from '../config';

export const METHOD = { 'POST': 'POST', 'GET': 'GET', 'PUT': 'PUT', 'DELETE': 'DELETE' };

export const CALL = (method, endpoint, { params = null, data = null, notify = null }) => {
    let url = `${config.API_BASE}/${endpoint}`;
    if (params) {
        url += `?${params}`;
    }

    let options = {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${config.USER_TOKEN}`
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    }
    if (data) {
        options.body = JSON.stringify(data)
    }
    return fetch(url, options).then((res) => {
        return res.json();
    }).then((res) => {
        if (res && res.code && (res.code < 200 || res.code >= 300)) throw res;
        return res;
    }).catch((err) => {
        const errorMessage = err && err.message ? err.message : err;
        if (notify) notify(errorMessage, { type: 'warning' });
        if (err && (err.code === 401 || err.code === 403)) {
            authProvider.logout();
        }
        throw err;
    });
}
