import { Buffer } from 'buffer';
import { CALL, METHOD } from './api';
import config from '../config';

function getUserData() {
    const userB64Data = localStorage.getItem('userdata');
    if (!userB64Data) return null;
    const userDataRaw = Buffer.from(userB64Data, 'base64').toString('utf-8');
    if (!userDataRaw) return null;
    let userData;
    try {
        userData = JSON.parse(userDataRaw);
    } catch (err) { }
    if (!userData) return null;
    return userData;
}

const authProvider = {
    // called when the user attempts to log in
    login: ({ username, password }) => {
        const loginDetails = {
            email: username, password
        }
        return CALL(METHOD.POST, 'auth/login', { data: loginDetails }).then((userData) => {
            if (!userData || !userData.tokens || !userData.tokens.refresh || !userData.tokens.refresh.token) throw new Error('Login failed, please try again!');
            config.USER_TOKEN = userData.tokens.access.token;
                localStorage.setItem('userdata', Buffer.from(JSON.stringify(userData)).toString('base64'));
        }).catch((error) => {
            throw error;
        });
    },
    // called when the user clicks on the logout button
    logout: () => {
        const userData = getUserData();
        if (!userData || !userData.tokens || !userData.tokens.refresh || !userData.tokens.refresh.token){
            localStorage.removeItem('userdata');
            return Promise.resolve();
        }
        const logoutDetails = { 'refreshToken': userData.tokens.refresh.token};
        return CALL(METHOD.POST, 'auth/logout', { data: logoutDetails }).catch((error) => {
        }).finally(()=>{
            localStorage.removeItem('userdata');
        });
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('username');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication 
    checkAuth: () => {
        const userData = getUserData();
        if (!userData || !userData.tokens || !userData.tokens.access || !userData.tokens.access.token) return Promise.reject();
        config.USER_TOKEN = userData.tokens.access.token;
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};

export default authProvider;