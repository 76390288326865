import { CALL, METHOD } from './api';

export const dataProvider = {
    getList: (endpoint, options) => {
        return CALL(METHOD.GET, endpoint, {}).then((res) => {
            return res;
        });
    },

    update: (endpoint, options) => {
        return CALL(METHOD.POST, endpoint, { data: options.data }).then((res) => {
            return res;
        });
    }
};